<template>
  <div class="py-4 container-fluid">
    <card v-if="appointments.length > 0">
      <h5 class="mx-4">Upcoming Bookings:</h5>
      <div
        v-for="(appointment, index) in appointments"
        :key="index"
        class="card mb-1"
      >
        <div class="card-body">
          <h5 class="card-title">{{ appointment.date }}</h5>
          <p class="card-text mb-0">
            <a :href="appointment.link" class="btn btn-success"
              >Go To Meeting Now</a
            >
          </p>
        </div>
      </div>
    </card>

    <div
      ref="tidyCal"
      class="tidycal-embed"
      data-path="fatforweightloss/1-1-coaching-call"
    ></div>
  </div>
</template>
<script>
import axios from "axios";
import supabase from "../../data/createClient.js";

export default {
  name: "TidyCal",
  data() {
    return {
      appointments: [],
    };
  },
  async mounted() {
    console.log("mounted");
    this.loadTidyCal();
    await this.getTidyCalAppointments();
  },
  async updated() {
    console.log("refreshe");
    this.loadTidyCal();
  },
  methods: {
    loadTidyCal() {
      console.log("loading tidy cal");
      // Create a new script element and set its attributes
      const script = document.createElement("script");
      script.src = "https://assets.tidycal.com/js/embed.js";
      script.async = true;

      setTimeout(() => {
        document.body.appendChild(script);
      }, 1000);
    },
    async getTidyCalAppointments() {
      const bearerAuth = process.env.VUE_APP_TIDYCAL_API_KEY;
      const { data } = await supabase.auth.getSession();

      const response = await axios.get("https://tidycal.com/api/bookings", {
        headers: {
          Authorization: "Bearer " + bearerAuth,
        },
      });

      if (response.status == 200) {
        const appointmentsForUser = response.data.data.filter(
          (appointment) =>
            appointment.contact.email.toLowerCase() ===
            data.session.user.email.toLowerCase()
        );

        appointmentsForUser.sort(
          (a, b) => new Date(a.starts_at) - new Date(b.starts_at)
        );

        for (const appointment of appointmentsForUser) {
          if (appointment.cancelled_at !== null) {
            continue;
          }

          if (new Date(appointment.ends_at) < new Date()) {
            continue;
          }

          const date = new Date(appointment.starts_at);
          const link = appointment.meeting_url;

          this.appointments.push({
            date: date,
            link: link,
          });
        }
      }
    },
  },
};
</script>
<style></style>
