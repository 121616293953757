<template>
  <div class="container">
    <h1 class="text-center pt-0">Macro Calculator</h1>

    <h5 class="text-center">Select Date:</h5>
    <div class="input-group input-group-static p-0 justify-content-center">
      <material-button
        variant="outline"
        color="info"
        size="sm"
        @click="previousDay"
        >&lt;</material-button
      >
      <span class="d-flex align-items-center px-4">{{ day.date }}</span>
      <material-button variant="outline" color="info" size="sm" @click="nextDay"
        >&rtri;</material-button
      >
    </div>

    <div>
      Calories:
      {{
        items.reduce(
          (total, item) =>
            total + parseInt(item.protein * 4 + item.fats * 9 + item.carbs * 4),
          0
        )
      }}
      /
      {{ macroGoals.calories }}
      <material-progress
        class="mb-2"
        :percentage="caloriePercentage"
        style="height: 20px"
      ></material-progress>
      Protein:
      {{ items.reduce((total, item) => total + parseInt(item.protein), 0) }} /
      {{ macroGoals.protein }}g
      <material-progress
        class="mb-2"
        :percentage="proteinPercentage"
        style="height: 20px"
      ></material-progress>
      Carbs:
      {{ items.reduce((total, item) => total + parseInt(item.carbs), 0) }} /
      {{ macroGoals.carbs }}g
      <material-progress
        class="mb-2"
        :percentage="carbsPercentage"
        style="height: 20px"
      ></material-progress>
      Fat: {{ items.reduce((total, item) => total + parseInt(item.fats), 0) }} /
      {{ macroGoals.fat }}g
      <material-progress
        class="mb-2"
        :percentage="fatPercentage"
        style="height: 20px"
      ></material-progress>
    </div>

    <div class="d-flex pt-4 justify-content-center">
      <div class="input-group-static p-1 input-group-custom">
        <material-button
          variant="outline"
          color="info"
          size="m"
          @click="showModal(true, false, false)"
        >
          + Food
        </material-button>
      </div>

      <div class="input-group-static p-1 input-group-custom">
        <material-button
          variant="outline"
          color="info"
          size="m"
          @click="showModal(false, true, false)"
        >
          + Quick
        </material-button>
      </div>

      <div class="input-group-static p-1 input-group-custom">
        <material-button
          variant="outline"
          color="info"
          size="m"
          @click="showModal(false, false, true)"
        >
          + Describe
        </material-button>
      </div>
    </div>

    <!-- show ai describe food modal -->
    <div v-show="showDescribeFoodModal" title="Describe Food">
      <div class="d-flex flex-column">
        <hr />
        <div class="pt-2">
          <material-textarea
            id="Search for your food here"
            v-model="searchText"
            type="text"
            class="form-control"
            placeholder="This only works for simple foods (i.e. 3 large eggs, 30g cheese etc). Accuruacy of these results are not guaranteed."
          />
          <material-button
            variant="outline"
            color="success"
            size="sm"
            :full-width="true"
            :disabled="!searchText || isSearchLoading"
            @click="describeFood()"
          >
            Search
          </material-button>
        </div>
        <div
          v-if="isSearchLoading"
          class="d-flex align-items-center justify-content-center p-2"
        >
          <div
            class="spinner-border"
            role="status"
            style="width: 3rem; height: 3rem"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Modal -->
    <div v-show="showFoodModal" title="Add Food">
      <div class="d-flex flex-column">
        <hr />
        <div class="pt-2 d-flex justify-content-center">
          <input
            id="Search for your food here"
            v-model="searchText"
            type="text"
            class="form-control"
            placeholder="Search"
            @keyup.enter="searchForFood"
          />
          <material-button
            variant="outline"
            color="success"
            size="sm"
            @click="searchForFood()"
          >
            Go
          </material-button>
        </div>
        <div
          v-if="isSearchLoading"
          class="d-flex align-items-center justify-content-center"
        >
          <div
            class="spinner-border"
            role="status"
            style="width: 3rem; height: 3rem"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div>
          <div v-if="searchedItems.length > 0" class="scrollable-list">
            <div
              v-for="(item, index) in searchedItems"
              :key="index"
              class="list-item"
              @click="addItem(item)"
            >
              <div class="text-left">
                <strong>{{ item.food }}</strong>
              </div>
              <div v-if="item.protein" class="px-2">
                p: {{ item.protein }} c: {{ item.carbs }} f: {{ item.fats }}
                {{ item.weight > 0 ? "per " + item.weight + " g" : "per 100g" }}
              </div>
              <div class="px-2">
                {{ item.brand }}
              </div>
            </div>
          </div>
        </div>
        <div class="pt-2 d-flex justify-content-end">
          <div class="form-check form-switch d-flex align-items-center">
            <input
              id="brandedSerach"
              v-model="myFoodsSearch"
              class="form-check-input m-2"
              type="checkbox"
            />
            <label class="form-check-label mt-2 mx-2" for="brandedSearch">
              My Foods
            </label>
            <input
              id="brandedSerach"
              v-model="brandedSearch"
              class="form-check-input m-2"
              type="checkbox"
            />
            <label class="form-check-label mt-2 mx-2" for="brandedSearch">
              Branded Items
            </label>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <!-- Search food Open Modal -->
    <div
      v-if="showSearchFoodModal"
      :style="{ display: showSearchFoodModal ? 'block' : 'none' }"
      class="modal"
    >
      <div class="modal-content">
        <div
          v-if="isSearchLoading"
          class="d-flex align-items-center justify-content-center m-5"
        >
          <div
            class="spinner-border"
            role="status"
            style="width: 3rem; height: 3rem"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="py-10"></div>
        </div>
        <div v-else>
          <span class="close" @click="showSearchFoodModal = false"
            >&times;</span
          >
          <h2 class="text-center">{{ selectedSearchItem.food }}</h2>
          <form @submit.prevent="confirmAddItem">
            <div class="form-group">
              <div class="d-flex pb-2">
                <input
                  id="amount"
                  v-model="selectedSearchItem.weight"
                  type="text"
                  class="form-control outlined-input"
                  placeholder="Amount"
                />
                <select
                  v-if="selectedSearchItem.foodPortions.length > 0"
                  v-model="selectedSearchItem.weight"
                  class="form-control form-select p-2"
                >
                  <option
                    v-for="(portion, index) in selectedSearchItem.foodPortions"
                    :key="index"
                    :value="portion.gramWeight"
                    :default="portion.gramWeight"
                  >
                    {{ portion.measureUnit ? portion.measureUnit.name : "" }}
                  </option>
                </select>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Nutrition</th>
                  <th>
                    {{
                      selectedSearchItem.weight > 0
                        ? "per " +
                          selectedSearchItem.weight +
                          " " +
                          selectedSearchItem.foodPortions[0].measureUnit.name
                        : "Per 100g"
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Protein</td>
                  <td>{{ searchProteinAmount }}g</td>
                </tr>
                <tr>
                  <td>Carbs</td>
                  <td>{{ searchCarbsAmount }}g</td>
                </tr>
                <tr>
                  <td>Fats</td>
                  <td>{{ searchFatAmount }}g</td>
                </tr>
                <tr>
                  <td>Calories</td>
                  <td>{{ searchCaloriesAmount }}</td>
                </tr>
              </tbody>
            </table>

            <div class="form-group">
              <div class="d-grid gap-2 mt-2 d-md-block">
                <button
                  class="btn btn-outline-info"
                  type="button"
                  @click="confirmAddItem()"
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Quick Add Food Modal -->
    <div v-show="showQuickModal" title="Add Quick Food">
      <div class="d-flex flex-column">
        <hr />
        <div v-if="quicItemCalories > 0" class="d-flex justify-content-start">
          {{ quicItemCalories }} Calories
        </div>
        <div class="pt-2 d-flex justify-content-center">
          <input
            id="Name"
            v-model="quickItem.food"
            type="text"
            class="form-control"
            placeholder="Name"
          />
        </div>
        <div class="pt-2 d-flex justify-content-center">
          <input
            id="Protein"
            v-model="quickItem.protein"
            type="number"
            class="form-control"
            placeholder="Protein"
          />
        </div>
        <div class="pt-2 d-flex justify-content-center">
          <input
            id="Carbs"
            v-model="quickItem.carbs"
            type="number"
            class="form-control"
            placeholder="Carbs"
          />
        </div>
        <div class="pt-2 d-flex justify-content-center">
          <input
            id="Fats"
            v-model="quickItem.fats"
            type="number"
            class="form-control"
            placeholder="Fats"
          />
        </div>
        <div class="pt-2 d-flex justify-content-center">
          <input
            id="Weight"
            v-model="quickItem.weight"
            type="number"
            class="form-control"
            placeholder="Amount (optional)"
          />
        </div>
        <div class="pt-2 d-flex justify-content-center">
          <select
            v-model="quickItem.type"
            class="form-select p-2"
            aria-label="Default select example"
          >
            <option value="g">g</option>
            <option value="serving">serving</option>
          </select>
        </div>
        <div class="pt-2 d-flex align-items-center justify-content-end">
          <div class="form-check form-switch d-flex align-items-center">
            <input
              id="addToDatabase"
              v-model="addToDatabase"
              class="form-check-input mx-2"
              type="checkbox"
            />
            <label class="form-check-label mt-2" for="addToDatabase">
              Add to my foods
            </label>
          </div>
          <material-button
            variant="outline"
            color="info"
            size="sm"
            class="ms-3"
            @click="addQuickItem()"
          >
            Add
          </material-button>
        </div>
        <hr />
      </div>
    </div>

    <div></div>
    <div class="d-flex align-items-center justify-content-center">
      <div v-if="isLoading" class="spinner-border mt-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="!isLoading" class="mt-3">
      <table class="table table-hover">
        <thead>
          <tr class="text-left" style="padding: 0">
            <th class="col-10 col-md-9">Food</th>
            <th class="col-2 col-md-3">
              <div class="d-flex justify-content-end">Delete / Copy</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index" tabindex="0">
            <td
              class="text-left overflow-text col-10 col-md-10"
              @click="selectedItem(item)"
            >
              <strong>{{ item.food }}</strong>
              <br />
              {{ item.weight ? item.weight + item.type : "" }} cal:
              {{
                item.calories !== null ? Number(item.calories).toFixed(0) : ""
              }}
              p: {{ item.protein }} c: {{ item.carbs }} f:
              {{ item.fats }}
            </td>
            <td class="col-2 col-md-1 button-column">
              <!-- Change col-md-2 to col-md-1 -->
              <div class="d-flex justify-content-end">
                <button
                  class="btn btn-outline-danger btn-sm mx-1"
                  @click.stop="deleteRow(index, item.id)"
                >
                  X
                </button>
                <button
                  class="btn btn-outline-info btn-sm"
                  @click.stop="copyToToday(item)"
                >
                  <i class="fas fa-copy"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialProgress from "@/components/MaterialProgress.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import DateUtils from "@/common/dateutils";
import supabase from "../../data/createClient.js";
import UserSettings from "../../data/user/userSettings.js";
//import axios from "axios";
import Nutrition from "../../data/nutrition/nutrition.js";
import { logError } from "../../errors/rollbar.js";
import { getFoods, getFoodDetails } from "../../data/foodSearch/foods.js";

export default {
  name: "MacroCalculator",
  components: { MaterialButton, MaterialProgress, MaterialTextarea },
  props: {},
  data() {
    return {
      fields: [
        "food",
        "weight",
        "calories",
        "protein",
        "carbs",
        "fats",
        "delete",
      ],
      items: [],
      quickItem: {
        food: "",
        calories: "",
        protein: "",
        carbs: "",
        fats: "",
        weight: "",
        type: "",
      },
      macroGoals: {
        calories: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
      },
      day: {
        id: "",
        date: DateUtils.formatLocalDate(),
      },
      searchedItems: [
        {
          food: "",
          protein: "",
          carbs: "",
          fats: "",
          calories: "",
          brand: "",
        },
      ],
      selectedSearchItem: {
        food: "",
        protein: "",
        carbs: "",
        fats: "",
        calories: "",
        foodPortions: [],
        weight: "",
        originalWeight: "",
        usdaid: "",
      },
      tableSelectedItem: null,
      isLoading: false,
      isSearchLoading: false,
      showQuickModal: false,
      showFoodModal: false,
      showSearchFoodModal: false,
      showDescribeFoodModal: false,
      searchText: "",
      addToDatabase: false,
      brandedSearch: false,
      myFoodsSearch: false,
    };
  },
  computed: {
    quicItemCalories() {
      return (
        this.quickItem.protein * 4 +
        this.quickItem.carbs * 4 +
        this.quickItem.fats * 9
      );
    },
    proteinPercentage() {
      const totalProtein = this.items.reduce(
        (total, item) => total + parseInt(item.protein),
        0
      );

      return Math.round((totalProtein / this.macroGoals.protein) * 100);
    },
    carbsPercentage() {
      const totalCarbs = this.items.reduce(
        (total, item) => total + parseInt(item.carbs),
        0
      );

      return Math.round((totalCarbs / this.macroGoals.carbs) * 100);
    },
    fatPercentage() {
      const totalFat = this.items.reduce(
        (total, item) => total + parseInt(item.fats),
        0
      );

      return Math.round((totalFat / this.macroGoals.fat) * 100);
    },
    caloriePercentage() {
      const totalCalories = this.items.reduce(
        (total, item) =>
          total + parseInt(item.protein * 4 + item.carbs * 4 + item.fats * 9),
        0
      );

      return Math.round((totalCalories / this.macroGoals.calories) * 100);
    },
    searchProteinAmount() {
      return Math.round(
        this.selectedSearchItem.protein *
          (this.selectedSearchItem.weight /
            this.selectedSearchItem.originalWeight)
      );
    },
    searchCarbsAmount() {
      return Math.round(
        this.selectedSearchItem.carbs *
          (this.selectedSearchItem.weight /
            this.selectedSearchItem.originalWeight)
      );
    },
    searchFatAmount() {
      return Math.round(
        this.selectedSearchItem.fats *
          (this.selectedSearchItem.weight /
            this.selectedSearchItem.originalWeight)
      );
    },
    searchCaloriesAmount() {
      return (
        this.searchProteinAmount * 4 +
        this.searchCarbsAmount * 4 +
        this.searchFatAmount * 9
      );

      // return Math.round(
      //   calcuatedCalories *
      //     (parseInt(this.selectedSearchItem.weight) /
      //       parseInt(this.selectedSearchItem.originalWeight))
      //);
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getMacros();
    await this.loadItems();
    this.isLoading = false;
  },
  methods: {
    async nextDay() {
      const date = new Date(this.day.date);
      date.setDate(date.getDate() + 1);
      this.day.date = date.toISOString().slice(0, 10);

      this.loadItems();
    },
    async previousDay() {
      const date = new Date(this.day.date);
      date.setDate(date.getDate() - 1);
      this.day.date = date.toISOString().slice(0, 10);

      this.loadItems();
    },
    async deleteRow(index, id) {
      const confirm = await this.$swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this item!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      });

      if (!confirm.isConfirmed) return;

      const response = await Nutrition.deleteDiaryItem(id);

      if (response.error) {
        alert("Error deleting item");
        return;
      }
      this.items.splice(index, 1);
    },

    async copyToToday(item) {
      const { value: confirm } = await this.$swal.fire({
        title: 'Copy item to?',
        html: '<label for="swal-input1">Select a date</label><input id="swal-input1" class="swal2-input" placeholder="Select a date" type="date">',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value
          ]
        },
        showCancelButton: true,
        confirmButtonText: 'Copy',
        cancelButtonText: 'Cancel',
      });

      if (!confirm) return;

      const insertItem = {
        food: item.food,
        weight: item.weight,
        type: item.type,
        calories: item.calories,
        protein: item.protein,
        carbs: item.carbs,
        fats: item.fats,
        date: confirm[0],
        usdaid: item.usdaid,
      };

      const { error } = await Nutrition.insertDiaryItem(insertItem);

      if (error) {
        console.error("Error inserting data:", error.message);
        return;
      } else {
        this.$swal.fire({
          title: "Success",
          text: `${item.food} copied successfully!`,
          icon: "success",
        });
      }

      this.loadItems();
    },
    async loadItems() {
      this.isLoading = true;
      const response = await Nutrition.getDiaryItems(this.day.date);

      if (response.error) {
        console.error("Error fetching data:", response.error.message);
        return;
      }

      if (response) {
        this.items = response.map((item) => {
          return {
            id: item.id,
            food: item.food,
            weight: item.weight,
            type: item.type,
            calories: item.calories,
            protein: item.protein,
            carbs: item.carbs,
            fats: item.fats,
            usdaid: item.usdaid,
          };
        });
      }
      this.isLoading = false;
    },
    async selectedItem(item) {
      await this.addItem(item);
    },
    async addItem(item, isUpdate = false) {
      if (item === null) return;

      this.showSearchFoodModal = true;
      this.isSearchLoading = true;

      this.selectedSearchItem = null;

      console.log(item);

      if (!item.usdaid) {
        this.selectedSearchItem = {
          id: item.id,
          food: item.food,
          protein: item.protein,
          carbs: item.carbs,
          fats: item.fats,
          calories: item.calories,
          foodPortions: [
            {
              amount: 1,
              gramWeight: item.weight,
              measureUnit: {
                name: item.type,
              },
            },
          ],
          weight: item.weight,
          originalWeight: item.weight,
          isUpdate: isUpdate,
        };

        console.log("this is the non lookup item", this.selectedSearchItem);

        this.isSearchLoading = false;
        return;
      }

      try {
        this.selectedSearchItem = await getFoodDetails(item);
        console.log("this is the item", this.selectedSearchItem);
      } catch (err) {
        console.log(err);
      }

      this.isSearchLoading = false;
    },
    async confirmAddItem() {
      const item = this.selectedSearchItem;

      if (item.weight === "") {
        alert("Please enter a weight");
        return;
      }

      item.protein = Math.round(
        item.protein * (item.weight / item.originalWeight)
      );
      item.carbs = Math.round(item.carbs * (item.weight / item.originalWeight));
      item.fats = Math.round(item.fats * (item.weight / item.originalWeight));
      item.calories = Math.round(
        item.calories * (item.weight / item.originalWeight)
      );

      const insertItem = {
        id: item.id,
        food: item.food,
        weight: item.weight,
        type: "g",
        calories: item.calories,
        protein: item.protein,
        carbs: item.carbs,
        fats: item.fats,
        date: this.day.date,
        usdaid: item.usdaid,
      };

      const { error } = await Nutrition.insertDiaryItem(insertItem);

      if (error) {
        console.error("Error inserting data:", error.message);
        return;
      }

      this.items.push(insertItem);
      this.showFoodModal = false;
      this.showSearchFoodModal = false;
      this.searchText = "";

      this.loadItems();
    },
    showModal(showFoodModal, showQuickModal, showDescribeFood) {
      if (showFoodModal) {
        this.showFoodModal = !this.showFoodModal;
        this.showQuickModal = false;
        this.showDescribeFoodModal = false;
      } else if (showQuickModal) {
        this.showQuickModal = !this.showQuickModal;
        this.showFoodModal = false;
        this.showDescribeFoodModal = false;
      } else if (showDescribeFood) {
        this.showDescribeFoodModal = !this.showDescribeFoodModal;
        this.showFoodModal = false;
        this.showQuickModal = false;
      }

      this.searchText = "";
      this.searchedItems = [];
    },
    async getMacros() {
      const userid = await UserSettings.getUserId();

      if (!userid) return console.error("No user id provided");

      try {
        const {
          data: nutritionDetailId,
          error: fetcherror,
        } = await supabase
          .from("users")
          .select("nutritiondetailid")
          .eq("id", userid)
          .limit(1);

        if (fetcherror) {
          console.error("Error fetching data:", fetcherror.message);
          return;
        }

        if (nutritionDetailId.length === 0) {
          console.error("No nutrition detail id found");
          return;
        }

        const { data, error } = await supabase
          .from("nutritiondetails")
          .select("calories, protein, carbs, fats")
          .eq("id", nutritionDetailId[0].nutritiondetailid)
          .limit(1);

        if (error) {
          console.error("Error fetching data:", error.message);
        } else {
          this.macroGoals.calories = data[0].calories;
          this.macroGoals.protein = data[0].protein;
          this.macroGoals.carbs = data[0].carbs;
          this.macroGoals.fat = data[0].fats;
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    },
    async addQuickItem() {
      if (this.quickItem.name === "") {
        this.quickItem.name = "Quick Food";
      }

      const item = {
        food: this.quickItem.food,
        weight: this.quickItem.weight ? this.quickItem.weight : 100,
        type: this.quickItem.type ? this.quickItem.type : "g",
        calories: this.quicItemCalories,
        protein: this.quickItem.protein,
        carbs: this.quickItem.carbs,
        fats: this.quickItem.fats,
        date: this.day.date,
      };

      if (item.calories === 0) {
        alert("Please enter a calorie amount");
        return;
      }

      if (!item.carbs) item.carbs = 0;
      if (!item.protein) item.protein = 0;
      if (!item.fats) item.fats = 0;

      const { error } = await Nutrition.insertDiaryItem(item);

      if (error) {
        console.error("Error inserting data:", error.message);
        return;
      }

      this.items.push(item);
      this.quickItem = {
        name: "",
        calories: "",
        protein: "",
        carbs: "",
        fats: "",
      };

      if (this.addToDatabase) {
        const { error } = await Nutrition.insertFood(item);

        if (error) {
          alert("Error inserting food:", error.message);
        }
      }

      this.loadItems();

      this.showQuickModal = false;
    },
    async searchForFood() {
      this.isSearchLoading = true;

      if (this.myFoodsSearch) {
        this.searchMyFoods();
        return;
      }

      try {
        this.searchedItems = await getFoods(
          this.brandedSearch,
          this.searchText
        );
      } catch (err) {
        logError(err);
      } finally {
        this.isSearchLoading = false;
      }
    },
    async searchMyFoods() {
      this.isSearchLoading = true;
      try {
        const response = await Nutrition.searchMyFoods(this.searchText);

        if (response.error) {
          console.error("Error fetching data:", response.error.message);
          return;
        }

        if (response.length > 0) {
          this.searchedItems = response.map((item) => {
            return {
              food: item.description,
              protein: item.protein,
              carbs: item.carbs,
              fats: item.fats,
              calories: item.calories,
              weight: item.weight,
              type: item.type,
            };
          });
        } else {
          this.searchedItems = [
            {
              food: "No results found",
              protein: "",
              carbs: "",
              fats: "",
              calories: "",
            },
          ];
        }
      } catch (err) {
        console.log(err);
      }
      this.isSearchLoading = false;
    },
    async describeFood() {
      this.isSearchLoading = true;
      try {
        if (!this.searchText) {
          this.isSearchLoading = false;
          return;
        }

        const response = await Nutrition.describeFood(this.searchText);

        if (response.error) {
          console.error("Error fetching data:", response.error.message);
          return;
        }

        if (response.length > 0) {
          const items = response.map((item) => {
            return {
              food: item.food,
              protein: item.protein,
              carbs: item.carbs,
              fats: item.fats,
              calories: item.calories,
              weight: item.weight,
              type: item.type,
              date: this.day.date,
            };
          });

          // Create an HTML string that represents a table with the item names
          const itemNamesTable = items
            .filter((item) => item.food)
            .map((item) => `<tr><td>${item.food}</td></tr>`)
            .join("");

          const tableHTML = `
            <table style="margin-left: auto; margin-right: auto;">
              <thead>
                <tr>
                  <th>Foods:</th>
                </tr>
              </thead>
              <tbody>
                <br/>
                ${itemNamesTable}
              </tbody>
            </table>
          `;

          // Display the string in the SweetAlert dialog
          const confirm = await this.$swal.fire({
            title: "Are you sure?",
            html: `You will be adding these items to your diary: <br> ${tableHTML}`,
            showCancelButton: true,
            confirmButtonText: "Yes, add them!",
            cancelButtonText: "I'll try again",
          });

          if (!confirm.isConfirmed) {
            this.isSearchLoading = false;
            return;
          }

          for (let i = 0; i < items.length; i++) {
            const food = items[i];
            const { error } = await Nutrition.insertDiaryItem(food);

            if (error) {
              console.error("Error inserting data:", error.message);
            }

            this.items.push(food);
          }

          this.searchText = "";
          this.showDescribeFoodModal = false;

          this.loadItems();
        } else {
          this.searchedItems = [
            {
              food: "No results found",
              protein: "",
              carbs: "",
              fats: "",
              calories: "",
            },
          ];
        }
      } catch (err) {
        console.log(err);
      }
      this.isSearchLoading = false;
    },
  },
};
</script>

<style scoped>
.table {
  margin: 0 auto;
  width: 100%;
  max-width: 95%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th {
  padding-left: 0.5rem;
}

.button-column {
  width: 80px !important; /* Adjust this value to your needs */
}

.scrollable-list {
  max-height: 200px;
  overflow-y: auto;
}

.list-item {
  cursor: pointer;
}

.modal {
  position: fixed;
  left: 0;
  top: 50;
  width: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  max-width: 650px;
}

.outlined-input {
  outline: 1px solid #888;
  padding: 0.5rem;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.overflow-text {
  max-width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
